import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"



const rel1 = "\vtext {drift(%)} = 1.0";
const rel2 = "D \cdot t = 1500x300 mm";
const rel3 = "L = 3500 mm";
const rel4 = "\vrho_{l}(%) = 2.15";
const rel5 = "\vrho_{t}(%) = 0.71";
const rel6 = "f_{c}' = 18, 41.2 MPa";
const rel7 = "f_{y} = 450 MPa";
const rel8 = "f_{yh} = 417 MPa";
const rel9 = "P/(f_{c}' A_{g}) = 0.08, 0.19";
const rel10 = "M/VH = 2.3";
const rel11 = "\vtext {CFRP sheets 0.55mm thick,} \vepsilon_{rup}=0.015, f_{uf} = 3480MPa";
const rel12 = "\vtext {drift(%)} = 4.34";

const rel101 = "\vmu_{d} = 1.0";
const rel102 = "D \cdot t = 1500x300 mm";
const rel103 = "L = 3500 mm";
const rel104 = "\vrho_{l}(%) = 2.15";
const rel105 = "\vrho_{t}(%) = 0.71";
const rel106 = "f_{c}' = 18, 41.2 MPa";
const rel107 = "f_{y} = 450 MPa";
const rel108 = "f_{yh} = 417 MPa";
const rel109 = "P/(f_{c}' A_{g}) = 0.08, 0.19";
const rel110 = "M/VH = 2.3";
const rel111 = "\vtext {CFRP sheets 0.55mm thick,} \vepsilon_{rup}=0.015, f_{uf} = 3480MPa";
const rel112 = "\vmu_{d} = 5.65";
















const LSExperimental = () => {
  //javascript
  const title = 'Retrofitted Piers';
  const metatitle = 'Retrofitted Piers';
  const description = 'Meta Description for Retrofitted Piers';
  const metadescription = description;


  const scrollToRef = (ref) => window.scrollTo({
    left:0,
    top:ref.current.offsetTop,
    behavior: 'smooth'
  });
  
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);
  const Ref16 = useRef(null);
  const Ref17 = useRef(null);
  const Ref18 = useRef(null);
  const Ref19 = useRef(null);
  const Ref20 = useRef(null);
  const Ref21 = useRef(null);
  const Ref22 = useRef(null);
  const Ref23 = useRef(null);
  const Ref24 = useRef(null);
  const Ref25 = useRef(null);
  const Ref26 = useRef(null);
  const Ref27 = useRef(null);
  const Ref28 = useRef(null);
  
  const executeScroll = (e, ref) => {
    e.preventDefault();
    scrollToRef(ref);
  }
  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
        <li>
            <a className="more" href="../cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more" href="../rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more actclass" href="../hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      <div className="content">
        <section className="exp-section">
          <h1>Hollow Circular Piers</h1>
          <div className="content-table">
            <h2>Table 1: Hollow Circular Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button> Cheng, C. T., Mo, Y.L., Yeh, Y.K. (2005)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel1}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel2}</MathJax.Node><br /><MathJax.Node inline>{rel3}</MathJax.Node><br /><MathJax.Node inline>{rel4}</MathJax.Node><br /><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node><br /><MathJax.Node inline>{rel10}</MathJax.Node><br /><MathJax.Node inline>{rel11}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel12}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Rupture of CFRP jacket and longitudinal bars</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>Cheng, C. T., Mo, Y.L., Yeh, Y.K. (2005). Evaluation of As-Built, Retrofitted, and Repaired Shear-Critical Hollow Bridge Columns under Earthquake-Type Loading, Journal of Bridge Engineering, ASCE, vol.10, No. 5, pp 520-529, <a href="https://doi.org/10.1061/(ASCE)1084-0702(2005)10:5(520)">https://doi.org/10.1061/(ASCE)1084-0702(2005)10:5(520)</a></li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <div className="content-table">
            <h2>Table 2: Hollow Circular Piers: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility (μ<sub>d</sub>)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref2)}>[1]</button> Cheng, C. T., Mo, Y.L., Yeh, Y.K. (2005)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel101}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel102}</MathJax.Node><br /><MathJax.Node inline>{rel103}</MathJax.Node><br /><MathJax.Node inline>{rel104}</MathJax.Node><br /><MathJax.Node inline>{rel105}</MathJax.Node><br /><MathJax.Node inline>{rel106}</MathJax.Node><br /><MathJax.Node inline>{rel107}</MathJax.Node><br /><MathJax.Node inline>{rel108}</MathJax.Node><br /><MathJax.Node inline>{rel109}</MathJax.Node><br /><MathJax.Node inline>{rel110}</MathJax.Node><br /><MathJax.Node inline>{rel111}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel112}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Rupture of CFRP jacket and longitudinal bars</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref2}>Cheng, C. T., Mo, Y.L., Yeh, Y.K. (2005). Evaluation of As-Built, Retrofitted, and Repaired Shear-Critical Hollow Bridge Columns under Earthquake-Type Loading, Journal of Bridge Engineering, ASCE, vol.10, No. 5, pp 520-529, <a href="https://doi.org/10.1061/(ASCE)1084-0702(2005)10:5(520)">https://doi.org/10.1061/(ASCE)1084-0702(2005)10:5(520)</a></li>
            </ol>
          </div>
        </section>
        
      </div>
      
    </Layout>
  )
}

export default LSExperimental
